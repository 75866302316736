import { DateTime } from 'luxon';

export interface IProfilePayload {
  profileType: ProfileTypeEnumerated | string | null;
  kycMethod: string;
  countryAlpha3: string | null;
  dob: string | null;
  checkTypes: string[];
}

export enum ProfileTypeEnumerated {
  '2x_bureaus_or_1_bureau_1_id' = '2x_bureaus_or_1_bureau_1_id',
  'Citizen_NationalID' = 'Citizen_NationalID',
  'NonCitizen_Passport' = 'NonCitizen_Passport',
  'NonCitizen_Passport_No_Bio' = 'NonCitizen_Passport_No_Bio',
  'above18' = 'above18',
  'aml_media_only' = 'aml_media_only',
  'aml_only' = 'aml_only',
  'asia_verify_custom_check_kyc' = 'asia_verify_custom_check_kyc',
  'auto' = 'auto',
  'beneficiary' = 'beneficiary',
  'campaignagent' = 'campaignagent',
  'con_test_one_plus' = 'con_test_one_plus',
  'con_test_two_plus' = 'con_test_two_plus',
  'customer' = 'customer',
  'default' = 'default',
  'devicecheck' = 'devicecheck',
  'devicechecknostoponfail' = 'devicechecknostoponfail',
  'devicecheckonly' = 'devicecheckonly',
  'full' = 'full',
  'gov_id' = 'gov_id',
  'gov_id_media' = 'gov_id_media',
  'international' = 'international',
  'international_media' = 'international_media',
  'my-profile' = 'my-profile',
  'none' = 'none',
  'organisation' = 'organisation',
  'safe_harbour' = 'safe_harbour',
  'safe_harbour_id' = 'safe_harbour_id',
  'safe_harbour_id_media' = 'safe_harbour_id_media',
  'safe_harbour_media' = 'safe_harbour_media',
  'safe_harbour_plus' = 'safe_harbour_plus',
  'safe_harbour_plus_media' = 'safe_harbour_plus_media',
  'standard_kyc' = 'standard_kyc',
  'under18' = 'under18',
  'xbank' = 'xbank',
  'xcredit' = 'xcredit',
}

export class Profile {
  kycMethod: string;
  country = 'AUS';
  profileType: ProfileTypeEnumerated | string = ProfileTypeEnumerated.default;
  private config: any;
  private dob: string | null = null;
  private _hadcodedCheckTypes: string[] | null = null;

  constructor(
    profileType: ProfileTypeEnumerated | string | null,
    kycMethod: string,
  );
  constructor(
    profileType: ProfileTypeEnumerated | null,
    kycMethod: string,
    countryAlpha3: string | null,
    dob: string | null,
    configOrCheckType: { checkTypes: string[] } | any,
  );
  constructor(
    profileType: ProfileTypeEnumerated | null,
    kycMethod = 'electronic',
    countryAlpha3?: string | null,
    dob?: string | null,
    configOrCheckType?: { checkTypes: string[] } | any,
  ) {
    this.kycMethod = kycMethod;
    this.profileType = this.initialiseConfigOrCheckType(
      profileType,
      configOrCheckType,
    );
    if (countryAlpha3) this.country = countryAlpha3?.toLowerCase();
    if (dob) this.dob = dob;
  }
  initialiseConfigOrCheckType(
    profileType: ProfileTypeEnumerated | null,
    configOrCheckType: any,
  ): ProfileTypeEnumerated {
    let theProfileType = profileType;
    if (!configOrCheckType) {
      this._hadcodedCheckTypes = null;
    } else if (Array.isArray(configOrCheckType?.checkTypes)) {
      this._hadcodedCheckTypes = configOrCheckType.checkTypes;
    } else {
      this.config = configOrCheckType;
      const defaultProfileType = ProfileTypeEnumerated.default;
      theProfileType = theProfileType ?? defaultProfileType;
    }
    return theProfileType ?? ProfileTypeEnumerated.none;
  }
  setConfiguration(config: any) {
    this.config = config;
  }
  getConfiguration(): string[] {
    const config = this.config;
    // if there's no configuration for current country, fallback to default.
    // current country may already be default as well
    const isUnder18 =
      this.dob &&
      DateTime.now().diff(DateTime.fromISO(this.dob!), 'years').as('years') <
        18;
    // 1 check first for specific country and then for default
    //  a first check if there's configuration for profileType
    //  b if not, decide based on age
    const typeKey = this.profileType;
    const ageKey = isUnder18 ? 'under18' : 'above18';

    const mkPossibilitiesForCountry = (country) => [
      config.get(country).get(typeKey).value() as string,
      config.get(country).get(ageKey).value() as string,
    ];

    const countryAndFallback = [this.country, 'default'];
    const allFourPossibilities = countryAndFallback
      .map(mkPossibilitiesForCountry)
      .flat();

    // out of four possibilities (country type, country age, default type, default age), find the first configuration that exists
    const foundTypesAsString = allFourPossibilities.find(Boolean);
    // to prevent breaking when configuration is not present
    const foundTypesAsArray = foundTypesAsString?.split?.(',') || [];
    return foundTypesAsArray;
  }
  getProfileType(): string {
    return this.profileType;
  }
  setProfileType(input: ProfileTypeEnumerated) {
    this.profileType = input;
  }
  get checkTypes(): string[] {
    if (!this.config && !this._hadcodedCheckTypes) return [];
    if (!this.config && !!this._hadcodedCheckTypes)
      return this._hadcodedCheckTypes;
    // if is manual kyc, replace configured kyc check types with 'manual'
    const isManualKyc = this.kycMethod === 'manual';
    const kycTypes = ['id', 'id2', 'one_plus', 'two_plus'];

    let finalTypes: string[] = this.getConfiguration();
    //If finalTypes is profile, we just need to return profile, core will know what need to be run.
    if (finalTypes.includes('profile')) return ['profile'];
    if (isManualKyc) {
      const isNotKyc = (type: string) => !kycTypes.includes(type);
      const nonKycConfiguration = finalTypes.filter(isNotKyc);
      finalTypes = ['manual', ...nonKycConfiguration];
    }

    return finalTypes;
  }

  toJSON(): IProfilePayload {
    return {
      profileType: this.profileType,
      kycMethod: this.kycMethod,
      countryAlpha3: this.country,
      dob: this.dob,
      checkTypes: this.checkTypes, // because we can't guarantee configuration serialization, we just output a hardcoded list of chectypes
    };
  }

  static fromJSON(payload: IProfilePayload): Profile {
    const { profileType, kycMethod, countryAlpha3, dob, checkTypes } = payload;

    return new Profile(
      profileType as ProfileTypeEnumerated,
      kycMethod,
      countryAlpha3,
      dob,
      {
        checkTypes,
      },
    );
  }
  static default() {
    return new Profile(null, 'electronic');
  }
}
