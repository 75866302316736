import type { EventHub, ReactiveStore } from '@module/common';
import { OneSDKError } from '@module/common';
import { Applicant } from '@module/common/shared/models/Applicant';
import type { Document } from '@module/common/shared/models/Document';
import { Profile } from '@module/common/shared/models/Profile';
import type { ApplicantClient } from '@module/frankie-client/clients/ApplicantClient';
import type { Events, InternalState } from '@module/individual';

type MkSearchDeps = {
  client: ApplicantClient;
  eventHub: EventHub<Events>;
  state$: ReactiveStore<InternalState>;
  recipeName: string;
};
export const mkSearch = (deps: MkSearchDeps) => {
  return async () => {
    const { client, eventHub, state$, recipeName } = deps;

    const isLoading$ = state$.getRootAccessors('isLoading');
    let applicant: Applicant = null;
    let documents: Document[] = null;

    try {
      isLoading$.setValue(true);
      const found = await client.load();
      applicant = found.applicant;
      documents = found.documents;
    } catch (e) {
      if (client.entityId) {
        const errorMessage = `Critical error! There was an issue loading the individual's details. Is the entityId (${client.entityId}) used to create this session valid?`;
        eventHub.emit('error', new OneSDKError(errorMessage, e));

        throw new Error(errorMessage);
      } else {
        applicant = new Applicant();
        documents = [];

        const profile = new Profile(recipeName, 'electronic');
        applicant.profile = profile;
        applicant.customerReference = client.reference;
      }
    }
    const isPreloaded = Boolean(applicant?.entityId);
    state$.next({
      applicant,
      documents,
      device: {},
      isLoading: false,
      isPreloaded,
      isPersisted: isPreloaded,
    });
    // This is required because isPersisted is set to false every time applicant and documents are reset
    state$
      .getRootAccessors('isPersisted')
      .setValue(Boolean(applicant.entityId));
    isLoading$.setValue(false);
    eventHub.emit('data_loaded', {
      entityId: client.entityId,
      reference: client.reference,
      isPreloaded,
    });
  };
};
